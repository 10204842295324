<template>
  <v-dialog :value="value" persistent width="1300">
    <v-card color="#fafafa">
      <v-toolbar flat dark color="#1c3144" class="white--text">
        <v-toolbar-title>{{isNewItem ? 'Create DSD Item' : 'Edit Item'}}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn> 
      </v-toolbar>
      <v-container class="ma-0 pa-6" fluid>
        <v-row dense>
          <v-col sm="4" md="2" lg="2" xl="2">
            <v-text-field background-color="white" dense label="Order Code" outlined :readonly="!isDSD && !isNewItem"
              v-model="item.order_code" :hint="selectedVendor ? selectedVendor.name : ''">
              <template v-slot:append>
                <v-menu v-if="isDSD || isNewItem" offset-y close :close-on-content-click="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip, ...menu }" v-bind="attrs" class="mb-1" icon small>
                          <v-icon>mdi-information-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>DSD Vendor Lookup</span>
                    </v-tooltip>
                  </template>
                  <v-card class="pt-4" height="265">
                    <v-text-field class="mx-2" label="Find Vendor Code" prepend-inner-icon="mdi-magnify" outlined dense
                      background-color="white" v-model="vendorFilter" />
                    <span class="mx-4">Select to Apply Code</span>
                    <v-divider />
                    <v-list dense style="height:150px; overflow-y:auto;">
                      <v-list-item color="primary" v-for="vendor in filteredVendors" :key="vendor.code" @click="setVendorCode(vendor)"
                        link>
                        <v-list-item-content>
                          <v-list-item-title>{{vendor.name}}</v-list-item-title>
                          <v-list-item-subtitle>{{vendor.code}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col sm="3" md="3" lg="3" xl="3">
            <v-autocomplete :items="suppliers" background-color="white" dense item-text="description" item-value="id"
              label="Supplier" outlined :readonly="!isDSD && !isNewItem" return-object v-model="item.supplier" @change="$emit('getDepartments', item.supplier.id)"/>
          </v-col>
          <v-col>
            <v-text-field background-color="white" dense label="Description" outlined :readonly="!isDSD && !isNewItem"
              v-model="item.description" :rules="description_rules"/>
          </v-col>
          <v-col sm="1" md="1" lg="1" xl="1">
            <v-text-field background-color="white" dense label="Pack" outlined :readonly="!isDSD && !isNewItem" v-model="item.pack" />
          </v-col>
          <v-col sm="1" md="1" lg="2" xl="2">
            <v-text-field background-color="white" dense label="Size" outlined :readonly="!isDSD && !isNewItem" v-model="item.size" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="4" md="2" lg="2" xl="2">
            <v-text-field background-color="white" dense label="UPC" outlined :readonly="!isDSD && !isNewItem" v-model="item.upc" />
          </v-col>
          <v-col sm="3" md="3" lg="2" xl="2">
            <v-autocomplete :items="departments" background-color="white" dense item-text="name" item-value="id"
              label="Department" outlined :readonly="(!isDSD && !isNewItem) || !item.supplier" return-object v-model="item.department" />
          </v-col>
          <v-col sm="3" md="2" lg="2" xl="2">
            <v-text-field background-color="white" dense label="Case cost" outlined prefix="$" :readonly="!isDSD && !isNewItem"
              v-model="item.case_cost" @change="format('case_cost', item.case_cost)"/>
          </v-col>
          <v-col sm="3" md="2" lg="2" xl="2">
            <v-text-field background-color="white" dense label="Allowance" outlined prefix="$" :readonly="!isDSD && !isNewItem"
              v-model="item.allowance" @change="format('allowance', item.allowance)"/>
          </v-col>
          <v-col sm="1" md="1" lg="2" xl="2">
            <v-text-field background-color="white" dense label="SRP Count" outlined :readonly="!isDSD && !isNewItem"
              v-model="item.srp_count"/>
          </v-col>
          <v-col sm="2" md="2" lg="2" xl="2">
            <v-text-field background-color="white" dense label="SRP" outlined prefix="$" prepend-icon="mdi-at" :readonly="!isDSD && !isNewItem"
              v-model="item.srp" @change="format('srp', item.srp)"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-autocomplete :items="statuses" background-color="white" dense item-text="text" item-value="id"
              label="Status" outlined v-model="item.status" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar color="#fafafa" flat>
        <v-spacer />
        <v-btn v-if="!isNewItem && isDSD" class="red white--text mx-2" @click="confirmDelete=true">
          Delete
          <v-icon right>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn color="success" @click="isNewItem ? $emit('create', item) : $emit('save', item)">
          {{ isNewItem ? 'Save' : 'Update' }}
          <v-icon right>{{
            isNewItem ? 'mdi-checkbox-marked-circle-outline' : 'mdi-update'
          }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-dialog :value="confirmDelete" persistent width="400">
      <v-card>
        <v-card-title>
          <v-icon size="50" color="primary" class="mr-2">mdi-help-rhombus-outline</v-icon>
          Delete Item?
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this DSD item?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="confirmDelete=false">No</v-btn>
          <v-btn class="primary white--text" @click="$emit('delete', item), confirmDelete=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
export default {
  name: 'PriceBookItemEdit',

  props: [
    'value',
    'selected',
    'selectedItem',
    'suppliers',
    'departments',
    'dsdVendors'
  ],

  data() {
    return {
      confirmDelete: false,
      item: {},
      selectedVendor: {},
      vendorFilter: '',
      description_rules: [v => (v && v.length <= 30) || 'Description cannot exceed 30 characters']
    }
  },

  computed: {
    isNewItem() {
      return this._.isEmpty(this.selectedItem)
    },

    filteredVendors () {
      return this.dsdVendors.filter(v => {
        if (v.code.toLowerCase().includes(this.vendorFilter.toLowerCase()) || v.name.toLowerCase().includes(this.vendorFilter.toLowerCase())) {
          return v
        }
      })
    },

    isDSD () {
      return this.selectedItem.dsd
    },

    statuses() {
      return [{
          text: 'Active',
          id: 'A'
        },
        {
          text: 'Discontinued',
          id: 'D'
        }
      ]
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          if (this.isNewItem) {
            this.item.status = 'A'
          }
        }
      }
    },

    selectedItem: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!this._.isEmpty(value)) {
          this.item = this._.cloneDeep(this.selectedItem)
          this.$emit('getDepartments', value.supplier.id)
          for (const key in this.item) {
            if (key == 'case_cost' || key == 'allowance' || key == 'srp') {
              this.item[key] = parseFloat(this.item[key]).toFixed(2)
            }
          }
        }
      }
    },

    item: {
      deep: true,
      immediate: true,
      handler () {
        if (this.item.order_code)  {
          let dsdVendorCode = this.item.order_code.substring(0, 2).toUpperCase()
          this.selectedVendor = this.dsdVendors.find(v => {
            if (v.code === dsdVendorCode) {
              return v
            }
          })
        }
      }
    }
  },

  methods: {
    format (key, value) {
      this.item[key] = ((value * 100) / 100).toFixed(2)
    },

    setVendorCode (vendor) {
      this.item.order_code = vendor.code
      this.$forceUpdate()
    }
  }
}
</script>
